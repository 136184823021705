import { Api } from "../environment/environment";

const GetImagenesProyecto = async (CodigoProyecto) => {
  try {
    const resp = await Api.get(
      "Proyectos/GetImagenesProyecto?CodigoProyecto=" + CodigoProyecto
    );
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const GetProyectos = async () => {
  try {
    const resp = await Api.get("Proyectos/GetProyectos");
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const GetListaProyectos = async () => {
  try {
    const resp = await Api.get("Proyectos/GetListaProyectos");
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const GetDetalleProyectos = async (id) => {
  try {
    const resp = await Api.get(
      "Proyectos/GetDetalleProyectos?CodigoProyecto=" + id
    );
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const GetProyecto = async (id) => {
  try {
    const resp = await Api.get("Proyectos/GetProyecto?CodigoProyecto=" + id);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const GetServiciosProyecto = async (id) => {
  try {
    const resp = await Api.get(
      "Proyectos/GetServiciosProyecto?CodigoProyecto=" + id
    );
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export {
  GetImagenesProyecto,
  GetProyectos,
  GetDetalleProyectos,
  GetListaProyectos,
  GetProyecto,
  GetServiciosProyecto,
};
