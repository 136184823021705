import { Api } from "../environment/environment";

const ObtenerLinksRenders = async (CodigoProyecto) => {
  try {
    const resp = await Api.get(
      "Catalogo/ObtenerLinksRenders?CodigoProyecto=" + CodigoProyecto
    );
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const ObtenerImagenesLote = async (codigoLote) => {
  try {
    const resp = await Api.get(
      "Catalogo/ObtenerImagenLote?codigoLote=" + codigoLote
    );
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const GuardarImagenesLote = async (datos) => {
  try {
    const resp = await Api.post("Catalogo/GuardarImagenLote", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const EliminarImagenesLote = async (CodigoImagen) => {
  try {
    const resp = await Api.delete(
      "Catalogo/EliminarImagenLote?codigoImagen=" + CodigoImagen
    );
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
const ObtenerBancos = async () => {
  try {
    const resp = await Api.get("Catalogo/ObtenerBancos");
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const CrearBancos = async (datos) => {
  try {
    const resp = await Api.post("Catalogo/CrearBancos", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const CrearMetricas = async (datos) => {
  try {
    const resp = await Api.post("Catalogo/CrearMetricas", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const CrearBodega = async (datos) => {
  try {
    const resp = await Api.post("Catalogo/CrearBodega", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const ObtenerBodegas = async () => {
  try {
    const resp = await Api.get("Catalogo/ObtenerBodegas");
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const ObtenerMetricas = async () => {
  try {
    const resp = await Api.get("Catalogo/ObtenerMetricas");
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const CrearCuentaProveedores = async (datos) => {
  try {
    const resp = await Api.post("Catalogo/CrearCuentaProveedores", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const ObtenerListaCuentasProveedores = async () => {
  try {
    const resp = await Api.get("Catalogo/ObtenerListaCuentasProveedores");
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const ObtenerCuentasProveedores = async (CodigoProveedor) => {
  try {
    const resp = await Api.get(
      "Catalogo/ObtenerCuentasProveedores?CodigoProveedor=" + CodigoProveedor
    );
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const CrearProveedores = async (datos) => {
  try {
    const resp = await Api.post("Catalogo/CrearProveedores", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const ObtenerProveedores = async () => {
  try {
    const resp = await Api.get("Catalogo/ObtenerProveedores");
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const ObtenerProyectos = async () => {
  try {
    const resp = await Api.get("Catalogo/ObtenerProyectos");
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const ObtenerPrioridades = async () => {
  try {
    const resp = await Api.get("Catalogo/ObtenerPrioridades");
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const GetTopografias = async () => {
  try {
    const resp = await Api.get("Catalogo/GetTopografias");
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const GetEstadosLotes = async () => {
  try {
    const resp = await Api.get("Catalogo/GetEstadosLotes");
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const GetEmpresas = async () => {
  try {
    const resp = await Api.get("Catalogo/GetEmpresas");
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const GetCuentasBancarias = async (id) => {
  try {
    const resp = await Api.get(
      "Catalogo/GetCuentasBancarias?CodigoEmpresa=" + id
    );
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export {
  GetTopografias,
  GetEstadosLotes,
  GetEmpresas,
  GetCuentasBancarias,
  ObtenerProyectos,
  ObtenerPrioridades,
  ObtenerProveedores,
  CrearProveedores,
  ObtenerCuentasProveedores,
  ObtenerListaCuentasProveedores,
  CrearCuentaProveedores,
  ObtenerMetricas,
  ObtenerBodegas,
  CrearBodega,
  CrearMetricas,
  CrearBancos,
  ObtenerBancos,
  ObtenerImagenesLote,
  GuardarImagenesLote,
  EliminarImagenesLote,
  ObtenerLinksRenders,
};
