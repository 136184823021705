import { Api } from "../environment/environment";
import { Auth } from "aws-amplify";

const InicioSesion = async (datos) => {
  try {
    const resp = await Api.post("Authentication/InicioSesion", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const RegistrarUsuario = async (datos) => {
  try {
    const resp = await Api.post("Authentication/CrearNuevoUsuario", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const CognitoSesionActual = async () => {
  try {
    const currentSession = await Auth.currentSession();
    if (currentSession) {
      return Promise.resolve(currentSession);
    } else {
      return "";
    }
  } catch (err) {
    if (err === "No current user") {
      localStorage.clear();
      //sessionStorage.clear();
      signOut();      
      return Promise.resolve("");
    }
    return Promise.reject(err);
  }
};

async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

async function refreshCognitoToken() {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    return currentUser;
  } catch (error) {
    console.log("refreshCognitoToken error", error);
    signOut();
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }
}

export {
  InicioSesion,
  RegistrarUsuario,
  CognitoSesionActual,
  refreshCognitoToken,
};
