import { Link, useNavigate } from "react-router-dom";
import { Api } from "../../environment/environment";
import { Auth } from "aws-amplify";
import {
  ROL_ADMIN,
  ROL_AGENTE_VENTAS,
  ROL_CONTADOR,
  ROL_REEMBOLSOS,
} from "../../constants/constants";
import "./style.css";
import Image from "../Image";
import Icon from "../Icon";
import logoImg from "../../assets/img/brand/Innovar_logo vertical contorno2.png";
import Button from "../Button";
import { GetProyectos } from "../..//services/proyectos";
import toast from "react-hot-toast";
import { memo, useState, useEffect, useCallback } from "react";
import { handleEncrypt } from "../../util/util";
import { logOff, selectSession } from "../../features/sessionSlice";
import { useDispatch, useSelector } from "react-redux";

const Navbar = () => {
  const navigate = useNavigate();
  const [isLink, setIsLink] = useState();
  const [open, setOpen] = useState(false);
  const session = useSelector(selectSession);
  const codRol = session?.codigoRol;
  const dispatch = useDispatch();
  const permisosControlPersonal = [ROL_ADMIN, ROL_AGENTE_VENTAS];
  const permisosHistorialPagos = [ROL_ADMIN, ROL_CONTADOR];
  const permisosCompras = [ROL_ADMIN, ROL_REEMBOLSOS];

  async function signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
  async function handleCerrarSesion() {
    await signOut();
    dispatch(logOff());
    Api.defaults.headers.common = {};
    localStorage.clear();
    navigate("/Proyectos");
  }

  const ObtenerDatos = useCallback(async () => {
    try {
      const response = await GetProyectos();
      setIsLink(
        response?.result
          .filter((f) => f.codigo !== 6)
          .map((o) => {
            return {
              nombre: o.nombre,
              codigo: o.codigo,
              orden: o.orden,
              link: "/DetalleProyecto?cp=" + handleEncrypt(o.codigo),
            };
          })
      );
    } catch (err) {
      console.log("ObtenerDatos", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }, []);

  useEffect(() => {
    ObtenerDatos();
  }, [ObtenerDatos]);

  useEffect(() => {
    if (!Array.isArray(codRol)) {
      handleCerrarSesion();
    }
  }, []);

  return (
    <>
      <div
        className={`navbar-mobile anim aic ${
          open ? "navbar-mobile-open" : "navbar-mobile-close"
        }`}
      >
        <div className="flex flex-col text-center overflow-scroll pt-5 sm:pt-10 md:pt-15 items-center">
          <a
            className="text-blue-500 text-xl underline2 font-bold pl-4"
            href="/Proyectos"
          >
            Proyectos
          </a>
          {isLink?.map((o) => {
            return (
              <a
                key={o.codigo}
                className="text-[#777e90] text-xl underline2 font-bold"
                href={o.link}
              >
                {o.nombre.replace("Residencial", "")}
              </a>
            );
          })}
          {Array.isArray(codRol) &&
            codRol?.some((item) => permisosControlPersonal.includes(item)) && (
              <a
                className="text-[#777e90] text-xl underline2 font-bold"
                href="/ControlPersonal"
              >
                Control de Personal
              </a>
            )}
          {Array.isArray(codRol) &&
            codRol?.some((item) => permisosHistorialPagos.includes(item)) && (
              <a
                className="text-[#777e90] text-xl underline2 font-bold"
                href="/HistorialPagos"
              >
                Historial de Pagos
              </a>
            )}
          {Array.isArray(codRol) &&
            codRol?.some((item) => permisosCompras.includes(item)) && (
              <a
                className="text-[#777e90] text-xl underline2 font-bold"
                href="/Compras"
              >
                Compras
              </a>
            )}
        </div>
        <Icon
          onClick={() => setOpen((prev) => !prev)}
          name="Close"
          className="navbar-mobile--icon-close anim star-rotate hover"
        />

        {Array.isArray(codRol) && codRol.some((item) => item === 0) ? (
          <>
            <Link to="/Registro">
              <Button ghost>Registrarse</Button>
            </Link>
            <Link to="/login">
              <Button>Acceder</Button>
            </Link>
          </>
        ) : (
          <>
            <Button secondary type="button">
              {session?.correo?.toUpperCase()}
            </Button>
            <Button ghost type="button" onClick={() => handleCerrarSesion()}>
              CERRAR SESIÓN
            </Button>
          </>
        )}
      </div>
      <nav className="navbar--container aic anim ">
        <div className="navbar-nav aic ">
          <Icon
            name="Burger"
            className="navbar-burger anim star-rotate hover"
            onClick={() => setOpen((prev) => !prev)}
          />
          <Link to="/" className="">
            <Image src={logoImg} className="navbar-nav-brand " />
          </Link>
          <div className="navbar-nav-div" />
          <ul className="navbar-nav-links aic p-2">
            <a
              className="text-blue-500 underline2 text-lg font-bold mt-3"
              href="/Proyectos"
            >
              Proyectos
            </a>
            {isLink
              ?.sort((a, b) => a.orden - b.orden)
              .map((o) => {
                return (
                  <a
                    key={o.codigo}
                    className="navbar-link underline2"
                    href={o.link}
                  >
                    {o.nombre.replace("Residencial", "")}
                  </a>
                );
              })}

            {Array.isArray(codRol) &&
              codRol?.some((item) =>
                permisosControlPersonal.includes(item)
              ) && (
                <a className="navbar-link underline2" href="/ControlPersonal">
                  Control de Personal
                </a>
              )}
            {Array.isArray(codRol) &&
              codRol?.some((item) => permisosHistorialPagos.includes(item)) && (
                <a className="navbar-link underline2" href="/HistorialPagos">
                  Historial de Pagos
                </a>
              )}
            {Array.isArray(codRol) &&
              codRol?.some((item) => permisosCompras.includes(item)) && (
                <a className="navbar-link underline2" href="/Compras">
                  Compras
                </a>
              )}
          </ul>
        </div>
        {Array.isArray(codRol) && codRol.some((item) => item === 0) ? (
          <div className="navbar-btns aic">
            <Link to="/Registro">
              <Button ghost>Registrarse</Button>
            </Link>
            <Link to="/login">
              <Button>Acceder</Button>
            </Link>
          </div>
        ) : (
          <div className="navbar-btns aic">
            <Button secondary type="button">
              {session?.correo?.toUpperCase()}
            </Button>
            <Button ghost type="button" onClick={() => handleCerrarSesion()}>
              CERRAR SESIÓN
            </Button>
          </div>
        )}
      </nav>
    </>
  );
};

export default memo(Navbar);
