import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Button as MuiButton,
} from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import Image from "../Image";
import logoImg from "../../assets/img/brand/Innovar_logo vertical contorno2.png";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import CustomMenu from "../CustomMenu";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import { logOff } from "../../features/sessionSlice";
import { Api } from "../../environment/environment";
import toast from "react-hot-toast";
import Button from "../Button";

const CustomAppBar = ({
  codRoles = [],
  gruposMenu = [],
  cerrarMenu = () => {},
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, setListaOpcionesMenu] = useState([]);

  async function handleCerrarSesion() {
    await Auth.signOut();
    dispatch(logOff());
    Api.defaults.headers.common = {};
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }

  const revisarPermisos = useCallback(async () => {
    try {
      let opciones = [];
      if (codRoles.some((item) => item === 0)) {
        opciones.push(
          { nombre: "Iniciar Sesión", link: "/LogIn" },
          { nombre: "Crear Cuenta", link: "/Registro" }
        );
      } else {
        opciones.push({ nombre: "Cerrar Sesión", funcion: handleCerrarSesion });
      }
      setListaOpcionesMenu(opciones);
    } catch (err) {
      console.log("revisarPermisos", err);
      toast.dismiss();
      toast.error(err?.message);
    }
  }, []);

  useEffect(() => {
    revisarPermisos();
  }, [revisarPermisos]);

  return (
    <AppBar component="nav" color="default">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={cerrarMenu}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Image
          src={logoImg}
          className="navbar-nav-brand hover:cursor-pointer"
          onClick={() => navigate("/Proyectos")}
        />
        <Box
          sx={{
            display: "flex",
            marginLeft: "1rem",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Box
              sx={{
                display: { xs: "none", sm: "block" },
              }}
            >
              <div className="flex gap-2">
                {gruposMenu.map((item, index) => {
                  return (
                    <CustomMenu
                      key={index}
                      titulo={item.titulo}
                      opciones={item.opciones}
                    />
                  );
                })}
                <MuiButton
                  aria-haspopup="true"
                  onClick={() => navigate("/Nosotros")}
                >
                  <div className="text-black">Nosotros</div>
                </MuiButton>
                <MuiButton
                  aria-haspopup="true"
                  onClick={() => navigate("/Comisionista")}
                >
                  <div className="text-black">Comisionista</div>
                </MuiButton>
              </div>
            </Box>
          </div>
          <div className="flex gap-2">
            {codRoles.some((item) => item === 0) ? (
              <>
                <Link to="/Registro">
                  <Button ghost>Registrarse</Button>
                </Link>
                <Link to="/login">
                  <Button>Acceder</Button>
                </Link>
              </>
            ) : (
              <>
                <Button
                  ghost
                  type="button"
                  onClick={() => handleCerrarSesion()}
                >
                  CERRAR SESIÓN
                </Button>
              </>
            )}
          </div>
          {/* <CustomMenu
            id="menu-appbar"
            opciones={listaOpcionesMenu}
            icono={<AccountCircle />}
            isIconButton={true}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          /> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default memo(CustomAppBar);
