import { Api } from "../environment/environment";

const urlBase = "Compras/";

const CerrarSolicitud = async (datos) => {
  try {
    const resp = await Api.put(urlBase + "CerrarSolicitud", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const EntregarArticulosSolicitud = async (datos) => {
  try {
    const resp = await Api.put(urlBase + "EntregarArticulosSolicitud", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const ObtenerSolicitudesCompras = async () => {
  try {
    const resp = await Api.get(urlBase + "ObtenerSolicitudesCompras");
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const ObtenerHistorialCompras = async () => {
  try {
    const resp = await Api.get(urlBase + "ObtenerHistorialCompras");
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const CrearSolicitudCompra = async (datos) => {
  try {
    const resp = await Api.post(urlBase + "CrearSolicitudCompra", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const EditarSolicitudCompra = async (datos) => {
  try {
    const resp = await Api.put(urlBase + "EditarSolicitudCompra", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const EliminarSolicitudCompra = async (codigoSolicitud) => {
  try {
    const resp = await Api.delete(
      urlBase + "EliminarSolicitudCompra?Codigo=" + codigoSolicitud
    );
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const EnviarSolicitudCompra = async (codigoSolicitud) => {
  try {
    const resp = await Api.post(
      urlBase + "EnviarSolicitudCompra",
      codigoSolicitud
    );
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const AprobarSolicitudCompra = async (datos) => {
  try {
    const resp = await Api.post(urlBase + "AprobarSolicitud", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const RechazarSolicitudCompra = async (datos) => {
  try {
    const resp = await Api.post(urlBase + "RechazarSolicitud", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const RechazarCotizacionCompra = async (datos) => {
  try {
    const resp = await Api.post(urlBase + "RechazarCotizacion", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const GuardarCotizaciones = async (datos) => {
  try {
    const resp = await Api.post(urlBase + "GuardarCotizaciones", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const EditarCotizaciones = async (datos) => {
  try {
    const resp = await Api.put(urlBase + "EditarCotizaciones", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const EnviarCotizaciones = async (datos) => {
  try {
    const resp = await Api.post(urlBase + "EnviarCotizaciones", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const ObtenerCotizaciones = async (CodigoSolicitud) => {
  try {
    const resp = await Api.get(
      urlBase + "ObtenerCotizaciones?CodigoSolicitud=" + CodigoSolicitud
    );
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const EliminarCotizaciones = async (CodigoCotizacion) => {
  try {
    const resp = await Api.delete(
      urlBase + "EliminarCotizaciones?CodigoCotizacion=" + CodigoCotizacion
    );
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const ObtenerComprobantes = async (CodigoSolicitud) => {
  try {
    const resp = await Api.get(
      urlBase + "ObtenerComprobantes?CodigoSolicitud=" + CodigoSolicitud
    );
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const EnviarComprobantes = async (datos) => {
  try {
    const resp = await Api.post(urlBase + "EnviarComprobantes", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const ObtenerComprobantesPago = async (CodigoSolicitud) => {
  try {
    const resp = await Api.get(
      urlBase + "ObtenerComprobantesPago?CodigoSolicitud=" + CodigoSolicitud
    );
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const GuardarComprobantesPago = async (datos) => {
  try {
    const resp = await Api.post(urlBase + "GuardarComprobantesPago", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const EditarComprobantesPago = async (datos) => {
  try {
    const resp = await Api.put(urlBase + "EditarComprobantesPago", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const EliminarComprobantesPago = async (CodigoComprobante) => {
  try {
    const resp = await Api.delete(
      urlBase +
        "EliminarComprobantesPago?CodigoComprobante=" +
        CodigoComprobante
    );
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const GuardarArticulos = async (datos) => {
  try {
    const resp = await Api.post(urlBase + "GuardarArticulos", datos);
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const ObtenerArticulosSolicitud = async (CodigoSolicitud) => {
  try {
    const resp = await Api.get(
      urlBase + "ObtenerArticulosSolicitud?CodigoSolicitud=" + CodigoSolicitud
    );
    return Promise.resolve(resp.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export {
  ObtenerSolicitudesCompras,
  CrearSolicitudCompra,
  EditarSolicitudCompra,
  EliminarSolicitudCompra,
  EnviarSolicitudCompra,
  AprobarSolicitudCompra,
  RechazarSolicitudCompra,
  GuardarCotizaciones,
  EnviarCotizaciones,
  ObtenerCotizaciones,
  EliminarCotizaciones,
  ObtenerComprobantes,
  EnviarComprobantes,
  GuardarComprobantesPago,
  EditarComprobantesPago,
  EliminarComprobantesPago,
  ObtenerComprobantesPago,
  GuardarArticulos,
  ObtenerArticulosSolicitud,
  EditarCotizaciones,
  EntregarArticulosSolicitud,
  CerrarSolicitud,
  RechazarCotizacionCompra,
  ObtenerHistorialCompras,
};
